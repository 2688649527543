class ImageLoader {
    //region Static
    static shared(downloader) {
        downloader = downloader || this.DOWNLOADER.IMG_TAG;

        if (!Object.values(this.DOWNLOADER).includes(downloader)) {
            console.warn("Provided downloader '" + downloader + "' is not known, reverting back to '" + this.DOWNLOADER.IMG_TAG + "'");
            downloader = this.DOWNLOADER.IMG_TAG;
        }

        this.__instances = this.__instances || {};

        if (!this.__instances[downloader]) {
            this.__instances[downloader] = new this(new window[downloader]());
        }

        if (!this.__boundMemoryWarningHandler) {
            this.__boundMemoryWarningHandler = this.onMemoryWarning.bind(this);
            addEvent("cordova-plugin-memory-alert.memoryWarning", window, this.__boundMemoryWarningHandler);
        }

        return this.__instances[downloader];
    }

    static destroy() {
        if (this.__instances) {
            Object.values(this.__instances).forEach(instance => {
                if ("destroy" in instance) {
                    try {
                        instance.destroy();
                    } catch (e) {
                        console.warn(`Couldn't destroy ImageLoader instance: ${e.message || e}`);
                    }
                }
            });
        }
    }

    static hasPersistentCache() {
        return window.hasOwnProperty("PlatformComponent") && !PlatformComponent.isWebInterface() && !PlatformComponent.isDeveloperInterface();
    }

    static onMemoryWarning() {
        if (this.__instances) {
            Object.values(this.__instances).forEach(instance => {
                if ("onMemoryWarning" in instance) {
                    try {
                        instance.onMemoryWarning();
                    } catch (e) {
                        console.warn(`Failed to handle memory warning: ${e.message || e}`);
                    }
                }
            });
        }
    }

    static DOWNLOADER = {
        IMG_TAG: "ImgTagDownloader",
        DL_SOCK: "DLSocketDownloader"
    };
    static RESPONSE_TYPE = {
        DATA_URI: "dataUri",
        SVG: "svg"
    }; //endregion Static

    /**
     * Loads an arbitrary Image URL with the given downloader and saves caches it
     * @param downloader
     */
    constructor(downloader) {
        this.name = "ImageLoader";
        this._downloader = downloader;
        this._persistentIndexFileName = downloader.name + "Index.json"; // The Index for the persistent cache (FileSystem), not available on Web and Developer Interface

        this._persistentIndex = {}; // This object caches the Images in a Javascript object, available on all platforms
        // This is for quicker access throughout an active app session, as its content doesn't originate from the hard drive or Flash

        this._initializeVolatileCache(); // keep track on what's currently being downloaded


        this._initializeDownloadTracker();

        this._readyPrms = this._initializePersistentCache();
    }

    /**
     * Loads a given image from an arbitrary URL
     * @param src
     * @param [ttl] now plus one week per default
     * @param [responseType]
     * @param [dontCache]
     * @return {Q.Promise<unknown>}
     */
    get(src, ttl, responseType = this.constructor.RESPONSE_TYPE.DATA_URI, dontCache = false) {
        var args = arguments;
        ttl = ttl || moment().add(1, "week").unix();
        src = this._normalizeSrc(src);
        Debug.ImageLoader && console.log(this.name, "get '" + src + "'");
        return this._readyPrms.then(function () {
            if (this._gotInVolatile(src)) {
                return Q.resolve(this._getFromVolatile(src));
            } else if (this._gotInPersistentCache(src)) {
                return this._getFromPersistence(src).then(b64 => b64, err => {
                    return this.get.apply(this, args);
                });
            } else {
                return this._downloadFromSrc(src, ttl, dontCache);
            }
        }.bind(this)).then(function (base64) {
            if (responseType === this.constructor.RESPONSE_TYPE.SVG) {
                Debug.ImageLoader && console.log(this.name, "get >> image Received, wrap in SVG: " + src);
                var def = Q.defer();
                getSvgImage(base64, null, null, def.resolve, def.reject);
                return def.promise.then(function (res) {
                    res.attr("ogSrc", src); // Helps for debugging

                    return res;
                });
            } else {
                Debug.ImageLoader && console.log(this.name, "get >> image Received, return: " + src);
                return base64;
            }
        }.bind(this)).finally(function () {
            Debug.ImageLoader && console.log(this.name, "get '" + src + "' passed!");
        }.bind(this));
    }

    purge(src) {
        src = this._normalizeSrc(src);
        Debug.ImageLoader && console.warn(this.name, "Purging '" + src + "'");

        if (src) {
            this._purgeVolatile(src);

            return this._purgePersistent(src);
        } else {
            Debug.ImageLoader && console.warn(this.name, "No src provided, purge aborted!");
            return Q.resolve();
        }
    }

    onMemoryWarning() {
        console.info("Received Memory Warning, purging volatile data...");
        Q.all(Object.keys(this._persistentIndex).map(src => {
            return this._purgeVolatile(this._normalizeSrc(src));
        })).then(() => {
            console.info("Purged all volatile data");
        });
    }

    _normalizeSrc(src) {
        // Detect Audioserver/Miniserver images and replace their host with the external URL if available
        return src;
    }

    // ------------------------------------------------------------------
    // -------------------- Persistence Handling ------------------------
    // ------------------------------------------------------------------
    _initializePersistentCache() {
        var prms;
        Debug.ImageLoader && console.log(this.name, "_initializePersistenceCache");

        if (this.constructor.hasPersistentCache()) {
            prms = PersistenceComponent.loadFile(this._persistentIndexFileName, DataType.OBJECT).then(function (index) {
                if (typeof index === "string") {
                    try {
                        this._persistentIndex = JSON.parse(index);
                    } catch (e) {
                        console.warn(e);
                    }
                } else {
                    this._persistentIndex = index;
                }

                if (this._persistentIndex) {
                    // Asynchronously check the persistentIndex & fill the volatile cache
                    setTimeout(this._checkPersistenceTtl.bind(this, true), 0);
                } else {
                    console.error(this.name, "NO _persistentIndex!");
                }
            }.bind(this), function () {
                return Q.resolve();
            });
        } else {
            console.error(this.name, "NO hasPersistentCache!");
            prms = Q.resolve();
        }

        return prms;
    }

    /**
     * Will iterate over the persistenceIndex & check all ttl timestamps.
     * @param fillVolatile  if true, the images from the persistenceIndex are loaded into the volatile cache.
     * @private
     */
    _checkPersistenceTtl(fillVolatile) {
        Debug.ImageLoader && console.log(this.name, "_checkPersistenceTtl: " + Object.keys(this._persistentIndex).length);
        var currentTime = moment().unix();
        Object.keys(this._persistentIndex).forEach(function (src) {
            var indexObj = this._persistentIndex[src]; // check if the TTL is overdue

            if (!indexObj.ttl || indexObj.ttl && indexObj.ttl < currentTime) {
                Debug.ImageLoader && console.warn(this.name, "  TTL (" + indexObj.ttl + ") passed, purge " + src);
                this.purge(src);
                delete this._persistentIndex[src];
            } else if (fillVolatile && !this._gotInVolatile(src)) {
                // ensure it's not already in the volatile cache.
                // Load the file and save it into the volatileCache to load the image more quicker!
                PersistenceComponent.loadFile(indexObj.fileName, DataType.STRING, Priority.LOW).done(function (base64) {
                    // ensure it's still not in the volatile cache. (e.g. loaded during file access)
                    !this._gotInVolatile(src) && this._storeToVolatile(src, base64);
                }.bind(this));
            }
        }.bind(this)); // recheck the persistence on a regular basis (every 12 hours)

        setTimeout(this._checkPersistenceTtl.bind(this), 60 * 60 * 12 * 1000);
    }

    _storeToPersistence(src, b64data, ttl) {
        if (this.constructor.hasPersistentCache()) {
            Debug.ImageLoader && console.log(this.name, "_storeToPersistence: " + src);
            var fileName = generateUUID();
            PersistenceComponent.saveFile(fileName, b64data, DataType.STRING).done(function () {
                this._persistentIndex[src] = {
                    ttl: ttl,
                    fileName: fileName
                };

                this._persistIndex();
            }.bind(this));
        } else {
            Debug.ImageLoader && console.error(this.name, "_storeToPersistence: " + src + " NO PERSISTENCE!");
        }
    }

    _gotInPersistentCache(src) {
        return this._persistentIndex.hasOwnProperty(src);
    }

    _getFromPersistence(src) {
        Debug.ImageLoader && console.log(this.name, "_getFromPersistence: " + src);
        return PersistenceComponent.loadFile(this._persistentIndex[src].fileName, DataType.STRING).then(function (base64) {
            Debug.ImageLoader && console.log(this.name, "_getFromPersistence >> Image loaded from persistence... " + src);

            this._storeToVolatile(src, base64);

            return base64;
        }.bind(this), function (err) {
            Debug.ImageLoader && console.log(this.name, "_getFromPersistence >> Image " + src + " couldn't be loaded form persistence, removing from index!");
            delete this._persistentIndex[src];

            this._persistIndex();

            return Q.reject(err);
        }.bind(this));
    }

    _persistIndex() {
        if (this._persistIndexTimeout) {
            clearTimeout(this._persistIndexTimeout);
            this._persistIndexTimeout = null;
        }

        this._persistIndexTimeout = setTimeout(function () {
            PersistenceComponent.saveFile(this._persistentIndexFileName, this._persistentIndex, DataType.OBJECT);
        }.bind(this), 10 * 1000);
    }

    _purgePersistent(src) {
        if (this.constructor.hasPersistentCache() && this._persistentIndex.hasOwnProperty(src)) {
            return PersistenceComponent.deleteFile(this._persistentIndex[src].fileName).finally(function () {
                delete this._persistentIndex[src];

                this._persistIndex();
            }.bind(this));
        } else {
            return Q.resolve();
        }
    }

    // ------------------------------------------------------------------
    // --------------------- Actual Image Downloads ---------------------
    // ------------------------------------------------------------------
    _downloadFromSrc(src, ttl, dontCache) {
        if (this._isDownloadingFromSrc(src)) {
            Debug.ImageLoader && console.log(this.name, "_downloadFromSrc: " + src + " -  already downloading!");
            return this._getDownloadPromise(src);
        } else {
            Debug.ImageLoader && console.log(this.name, "_downloadFromSrc: " + src);
            return this._trackDownloadOf(src, this._downloader.load(src).then(function (base64) {
                return this._handleSuccessfulDownload(src, base64, dontCache, ttl);
            }.bind(this), function (err) {
                return this._handleDownloadError(err, src, dontCache, ttl);
            }.bind(this)));
        }
    }

    _handleSuccessfulDownload(src, base64, dontCache, ttl) {
        Debug.ImageLoader && console.log(this.name, "_handleSuccessfulDownload: " + src);

        if (!this._persistentIndex.hasOwnProperty(src) && !dontCache) {
            this._storeToVolatile(src, base64);

            this._storeToPersistence(src, base64, ttl);
        }

        return base64;
    }

    _handleDownloadError(error, src, dontCache, ttl) {
        Debug.ImageLoader && console.error(this.name, "_handleDownloadError: " + src); // as e.g. the tune-in CDN doesn't have CORS headers set & safari strictly checks for those, try to acquire
        // the image cached on our servers for that radio station.

        if (src.indexOf("tunein.loxonecloud.com") >= 0 && src.indexOf("?dynamic") >= 0) {
            var newPath = src.split("?dynamic")[0];
            Debug.ImageLoader && console.error(this.name, "   >> retry to download without dynamic-arguments: " + newPath);
            return this._downloader.load(newPath).then(function (base64) {
                // handle just as if the regular src responded --> cache it.
                return this._handleSuccessfulDownload(src, base64, dontCache, ttl);
            }.bind(this));
        } else {
            return Q.reject(error);
        }
    }

    _initializeDownloadTracker() {
        this._loaderIndex = {};
    }

    _trackDownloadOf(src, promise) {
        this._loaderIndex[src] = promise.finally(function () {
            delete this._loaderIndex[src];
        }.bind(this));
        return this._loaderIndex[src];
    }

    _isDownloadingFromSrc(src) {
        return this._loaderIndex.hasOwnProperty(src);
    }

    _getDownloadPromise(src) {
        return this._loaderIndex[src];
    }

    // ------------------------------------------------------------------
    // ------------------- volatile cache handling ----------------------
    // ------------------------------------------------------------------
    _initializeVolatileCache() {
        this._volatileCache = {};
    }

    _gotInVolatile(src) {
        Debug.ImageLoader && console.log(this.name, "_gotInVolatile: " + src + " = " + this._volatileCache.hasOwnProperty(src));
        return this._volatileCache.hasOwnProperty(src);
    }

    _storeToVolatile(src, b64data) {
        Debug.ImageLoader && console.log(this.name, "_storeToVolatile: " + src);
        this._volatileCache[src] = {
            data: b64data,
            ttl: moment().add(2, "hour")
        };
        !this._volatileTtlTimeout && this._startVolatileTtlCheck();
    }

    _getFromVolatile(src) {
        var cachedItem = this._volatileCache[src];
        Debug.ImageLoader && console.log(this.name, "_getFromVolatile: " + src, cachedItem);
        return cachedItem.data;
    }

    _purgeVolatile(src) {
        Debug.ImageLoader && console.log(this.name, "_purgeVolatile: " + src);
        delete this._volatileCache[src];

        if (Object.keys(this._volatileCache).length === 0) {
            this._stopVolatileTtlCheck();
        }
    }

    _checkVolatileTtls() {
        var keys = Object.keys(this._volatileCache),
            cachedItem = null,
            now = moment();
        Debug.ImageLoader && console.log(this.name, "_checkVolatileTtls: " + keys.length + " items cached!");
        keys.forEach(function (src) {
            cachedItem = this._volatileCache[src];

            if (cachedItem.ttl < now) {
                this._purgeVolatile(src);
            }
        }.bind(this));
    }

    _startVolatileTtlCheck() {
        Debug.ImageLoader && console.log(this.name, "_startVolatileTtlCheck");
        this._volatileTtlTimeout = setTimeout(function () {
            this._volatileTtlTimeout = null;

            this._checkVolatileTtls();

            if (Object.keys(this._volatileCache).length > 0) {
                this._startVolatileTtlCheck();
            }
        }.bind(this), 60 * 30 * 1000); // recheck every 30 minutes
    }

    _stopVolatileTtlCheck() {
        Debug.ImageLoader && console.log(this.name, "_stopVolatileTtlCheck");
        clearTimeout(this._volatileTtlTimeout);
        this._volatileTtlTimeout = null;
    }

}
